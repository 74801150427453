// Scroller
function scrollerInit() {
  var scrollVar = $('.hero--project__gallery').scrollTop();
  var ticking = false;

  function parallaxScoller(scrollHeight, scroller){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var headerHeight = $('.float-wrapper').height();
    scroller = scroller ? scroller : $('.scroll');

    if(windowWidth >= 768){
      var scrollAmount = (scrollVar / (scrollHeight - windowHeight + headerHeight)) * 100;
      scroller.css('height', scrollAmount + '%');
    }
  }

  function requestScrollerTick(scrollHeight, scroller) {
    if(!ticking) {
      window.requestAnimationFrame(function() {
        parallaxScoller(scrollHeight, scroller);
      });
    }
    ticking = true;
  }

  $('.hero--project').each(function(){
    var that = $(this);

    that.find('.hero--project__gallery').scroll(function(){
      scrollVar = $(this).scrollTop();
      scrollHeight = $(this).find('.inner').height();
      requestScrollerTick(scrollHeight, that.find('.scroll'));
    });
  });

  $('.main').scroll(function(){
    scrollVar = $(this).scrollTop();
    scrollHeight = $('.main article > .inner').height();
    requestScrollerTick(scrollHeight);
  });

  requestScrollerTick(0);
}

// Hero
function parallaxHeroInit() {
  var scrollVar = $(window).scrollTop();
  var ticking = false;

  // Hero
  var $depth1 = $('.hero--home .bg-img');
  var $depth2 = $('.hero--home .wrap');
  var $bottom = $('.bottom');

  // Everything plz
  var $all = $('.hero--home .bg-img, .hero--home .wrap');

  function parallaxHero(){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();

    if(windowWidth >= 768){
      // Hero
      var limitedScrollVar = scrollVar * 0.5;
      // var opacity = (1 - scrollVar/($(window).height() * 1.4));
      var opacity2 = (1.05 - scrollVar/($(window).height() * 0.8));

      $depth1.css({ 'transform': 'translate3d(0px, ' + (0.7 * limitedScrollVar) + 'px, 0px)' });
      $depth2.css({ 'transform': 'translate3d(0px, ' + (0.5 * limitedScrollVar) + 'px, 0px)', 'opacity': opacity2 });

      if($bottom.length > 0) {
        $bottom.css({ 'opacity': opacity });
      }
    }
    else {
      $all.css({ 'transform': 'initial', 'opacity': 1, 'top': 'auto' });
    }
  }

  function requestHeroTick() {
    if(!ticking) {
      window.requestAnimationFrame(parallaxHero);
    }
    ticking = true;
  }

  $(window).scroll(function(){
    scrollVar = $(window).scrollTop();
    requestHeroTick();
  });
  requestHeroTick();
}

// Init
function parallaxInit() {
  scrollerInit();
}