/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var BrowserDetect = {
      init: function () {
          this.browser = this.searchString(this.dataBrowser) || "Other";
          this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
      },
      searchString: function (data) {
          for (var i = 0; i < data.length; i++) {
              var dataString = data[i].string;
              this.versionSearchString = data[i].subString;

              if (dataString.indexOf(data[i].subString) !== -1) {
                  return data[i].identity;
              }
          }
      },
      searchVersion: function (dataString) {
          var index = dataString.indexOf(this.versionSearchString);
          if (index === -1) {
              return;
          }

          var rv = dataString.indexOf("rv:");
          if (this.versionSearchString === "Trident" && rv !== -1) {
              return parseFloat(dataString.substring(rv + 3));
          } else {
              return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
          }
      },

      dataBrowser: [
          {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
          {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
          {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
          {string: navigator.userAgent, subString: "Opera", identity: "Opera"}
      ]

  };

BrowserDetect.init();

if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
    }, false);
}

Modernizr.addTest('svgasimg', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));

function projectSwiper(mobileSwipe) {

  if($('.project-swiper')) {
    var swiperProject = new Swiper('.project-swiper', {
      direction: 'vertical',
      autoHeight: false,
      speed: 800,
      mousewheelControl: true,
      parallax: mobileSwipe ? false : true,
      onlyExternal: false,
      mousewheelEventsTarged: '.listing__inner',
      onTransitionStart: function(e) {
        $('.side-bar__nav.is-active').removeClass('is-active');
        $('.side-bar__nav').eq(e.activeIndex).addClass('is-active');
      },
    });

    $('.side-bar__nav').on('click', function() {
      swiperProject.slideTo($(this).data('link'));
    });

    $('.project-next').on('click', function() {
      swiperProject.slideNext();
    });

    $('.project__top').on('click', function() {
      swiperProject.slideTo(1);
    });

    var swiperListing = new Swiper('.listing__swiper', {
      nextButton: $('.button-details'),
      prevButton: $('.button-back'),
      watchSlidesProgress: true,
      speed: 700,
      parallax: false,
      onlyExternal: true,
      onSlideNextStart: function() {
        swiperProject.disableTouchControl();
        $('body').addClass('side-bar--hide');
      },
      onSlidePrevStart: function() {
        swiperProject.enableTouchControl();
        $('body').removeClass('side-bar--hide');
      },
      onProgress: function(swiper, progress){
        for (var i = 0; i < swiper.slides.length; i++){
          var slide = swiper.slides[i];
          var translate, innerTranslate;
          progress = slide.progress;

          if (progress > 0) {
            translate = progress * swiper.width;
            innerTranslate = translate * -0.5;
          }
          else {
            innerTranslate = Math.abs( progress * swiper.width ) * -0.5;
            translate = 0;
          }

          var image = $(slide).find('.listing__image');

          $(slide).css({
            transform: 'translate3d(' + translate*0.5 + 'px,0,0)'
          });
        }

        if(progress === 0) {
          swiperProject.disableTouchControl();
          $('body').addClass('side-bar--hide');
        }
        else if(progress === -1) {
          swiperProject.enableTouchControl();
          $('body').removeClass('side-bar--hide');
        }
      },
      onTouchStart: function(swiper){
        for (var i = 0; i < swiper.slides.length; i++){
          $(swiper.slides[i]).css({ transition: '' });
        }
      },
      onSetTransition: function(swiper, speed) {
        for (var i = 0; i < swiper.slides.length; i++){
          $(swiper.slides[i])
            .find('.slide-inner')
            .andSelf()
            .css({ transition: speed + 'ms' });
        }
      }
    });

    $('.project__next').on('click', function(e) {
      e.preventDefault();
      swiperProject.slideNext();
    });
  }
}

// Do things

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        svg4everybody();

        if(!Modernizr.touchevents){
          $("a[href*='tel:']").on('click',function(){
            return false;
          });
        }
        $("[data-block], a[href*='#']").on('click',function(){
          var href = $(this).attr('href');
          var $el = $($(this).data('block') || '#' + href.substring(href.indexOf("#") + 1));

          if($el.length > 0) {
            $('.nav--mobile--primary').data('loader').close();
            $("html").velocity("scroll", { offset: $el.offset().top, mobileHA: false, duration: 800 });
            return false;
          }
        });

        // Hash scroll
        if(window.location.hash && $(window.location.hash).length > 0) {
          // console.log(window.location.hash);
          $(window.location.hash).velocity("scroll");
        }

        $("a.video").magnificPopup({
          type:'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 300,
           callbacks:{
            open:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.pause();
              }
            },
            close:function(){
              var pInstance = $(".hero--home").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.play();
              }
            }
          },
          iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                      '<div class="mfp-close"></div>'+
                      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
              youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
              },
              gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed'
              }
            }
          }
        });

        //fire the map if it exists
        var gmapScript ='https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit&key='+GOOGLE_API_KEY;
        if($("#map-canvas").length !== 0){
          $.getScript( gmapScript )
            .done(function( script, textStatus ) {
              //console.log( script );
            })
            .fail(function( jqxhr, settings, exception ) {
              //$( "div.log" ).text( "Triggered ajaxError handler." );
          });
        }

        // Scroller
        scrollerInit();
      },
      finalize: function() {
        objectFitImages();

        stickyHeaderInit();

        $("nav.greedy").makeGreedy();

        // We probably don't need this right away
        var swiper = new Swiper ('.slider .swiper-container', {
          pagination: '.swiper-pagination',
          direction: 'horizontal',
          loop: true,
          slidesPerView: 4,
          paginationClickable: true,
          spaceBetween: 0,
          breakpoints: {
              1024: {
                  slidesPerView: 4,
                  spaceBetween: 0
              },
              768: {
                  slidesPerView: 3,
                  spaceBetween: 0
              },
              640: {
                  slidesPerView: 2,
                  spaceBetween: 0
              },
              320: {
                  slidesPerView: 1,
                  spaceBetween: 0
              }
          }
        });

        // Project Swiper

        // Team Loader
        if(document.querySelectorAll("[data-loader='team']").length > 0) {
          var teamLoader = new Loader('.loader-team', {
            triggerData: 'team',
            updateContent: true
          });
        }

        var menuLoader = new Loader('.nav--mobile--primary', {
          trigger: '.nav__toggle__trigger'
        });

        // Mobiles
        mobileNavInit();
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.logo').on('click', function(e) {
          e.preventDefault();
          $("html").velocity("stop", true).velocity("scroll", { offset: 0, mobileHA: false, duration: 800,
            begin: function() { $('html').data('going', true); },
            complete: function() { $('html').data('going', false).removeClass('lock'); }
          });
        });
      },
      finalize: function() {
        parallaxHeroInit();
        projectSwiper();
      }
    },
    // About us page, note the change from about-us to about_us.
    'not_front': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.js-gallery__item').magnificPopup({
          type: 'image',
          mainClass: 'mfp-fade',
          removalDelay: 300,
          gallery:{
            enabled:true
          },
          image: {
            markup: '<div class="mfp-figure">'+
                      '<div class="mfp-close"></div>'+
                      '<div class="mfp-img"></div>'+
                      '<div class="mfp-bottom-bar">'+
                        '<div class="mfp-title"></div>'+
                        '<div class="mfp-counter"></div>'+
                      '</div>'+
                    '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

            //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
            // Or the function that should return the title. For example:
            titleSrc: function(item) {
              //console.log($(item.el).find('img').attr('title'));
              return $(item.el).find('img').attr('title');
            },

            verticalFit: true, // Fits image in area vertically

            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
          },
          callbacks: {
            open: function() {
              $.magnificPopup.instance.next = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('next');
                setTimeout(function() {
                  $.magnificPopup.proto.next.call(self);
                }, 400);
              };
              $.magnificPopup.instance.prev = function() {
                var self = this;
                self.wrap.removeClass('mfp-image-loaded');
                self.wrap.addClass('prev');
                setTimeout(function() {
                  $.magnificPopup.proto.prev.call(self);
                }, 400);
              };
            },
            imageLoadComplete: function() {
              var self = this;
              self.wrap.addClass('mfp-image-loading');
              setTimeout(function() {
                self.wrap.removeClass('mfp-image-loading prev next');
                self.wrap.addClass('mfp-image-loaded');
              }, 10);
            }
          }
        });

      },
      finalize: function() {
        // Project swiper
        projectSwiper(true);
      }
    },
    'our_team': {
      init: function() {

      },
      finalize: function() {

      }
    },
    'contact': {
      init: function() {

      },
      finalize: function() {
      }
    },
    'single_project': {
      finalize: function() {
      }
    },
    'archive': {
      finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          setTimeout(function(){
            UTIL.fire(classnm, 'finalize');
          },200);
        });
        // Fire common finalize JS
        setTimeout(function(){
          UTIL.fire('common', 'finalize');
          console.info( "main.js file has fired all commands." );
        },200);

    }
  };

  //fool the shit out of pagespeed insights
  function loadTheCSS(){
    var stylesheet = loadCSS( cssFile, document.getElementById("css-ref") );
    onloadCSS( stylesheet, function() {
      console.info( "Stylesheet has loaded." );
      $(document).ready(UTIL.loadEvents);
    });
  }
  var botPattern = "(Insights)";
  var re = new RegExp(botPattern, 'i');
  var userAgent = navigator.userAgent;
  if (re.test(userAgent)) {
      window.setTimeout(loadTheCSS, 5000);
  }else{
    loadTheCSS();
  }

})(jQuery); // Fully reference jQuery after this point.
